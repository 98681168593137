import axios from 'axios';
import fd from 'form-data';
import _ from 'lodash';
import Swal from 'sweetalert2';

let host =
	(process.env.NODE_ENV === 'production'
		? process.server
			? process.env.host + '/api/' //prod на сервере
			: '/api/' //prod на клиенте
		: process.server
		? process.env.dev //dev на сервере
		: '/api/') + '?option=com_sp&view=api'; //dev на клиенте

export const state = () => ({
	session: '',
	token: '',
	meta: {
		title: '',
		description: '',
		image: '',
	},
	init: false,
	query: [],
	lastQuery: [],
	loading: false,
	socials: [],
	calendar: {
		/**
		 * @authUrl string url авторизации пользователя
		 * @access_token string токен google oAuth
		 * ...
		 */
	},
	calendarList: [], //спиоск календарей
});

export const mutations = {
	setSession(state, value) {
		state.session = value.session;
		state.token = value.token;
	},
	setMeta(state, value) {
		_.each(value, (item, key) => (state.meta[key] = item));
	},
	clearQuery(state) {
		state.lastQuery = state.query;
		state.query = [];
	},
	addQuery(state, value) {
		state.query.push(value);
	},
	setInit(state, value) {
		state.init = value;
	},
	setLoading(state, value) {
		state.loading = value;
	},
	setSocials(state, value) {
		state.socials = value;
	},
	setCalendar(state, value) {
		state.calendar = value;
	},
	setCalendarList(state, value) {
		state.calendarList = value;
	},
};

export const actions = {
	async send($store, { task, data = {} }) {
		let formData = process.server ? new fd() : new FormData();
		formData.append('task', task);

		data['session_id'] = $store.state.session;
		formData.append($store.state.token, 1);
		formData.append('data', JSON.stringify(data));

		let mutations;
		if (process.server) {
			mutations = await fetch(host, {
				method: 'POST',
				body: formData,
			}).then(function(res) {
				return res.json();
			});
			return $store.dispatch('process', mutations);
		} else {
			if (!['checkUserAuth', 'checkUserEmail'].includes(task)) $store.commit('setLoading', true);
			mutations = await axios.post(host, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			});
			$store.commit('setLoading', false);
			return $store.dispatch('process', mutations.data);
		}
	},
	process($store, mutations) {
		_.each(mutations, item => {
			if (process.client) console.log('mutation', item);
			let mod;
			switch (item.name) {
				case 'setSessions':
					mod = 'schedule/';
					break;
				default:
					mod = '';
					break;
			}
			if (['notice', 'console'].indexOf(item.name) !== -1) {
				return $store.dispatch(item.name, item.data);
			} else {
				$store.commit(mod + item.name, item.data, { root: true });
			}
		});
		return mutations;
	},
	async notice($store, options) {
		return process.server ? console.log(options) : await Swal.fire(options);
	},
	async console($store, options) {
		return console.log('LOG: ', options);
	},
};
