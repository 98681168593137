import _ from 'lodash';
//import helper from '@/plugins/helper.js';

export const state = () => ({
	items: [],
	user: [], //абонементы клиента
});

export const mutations = {
	set(state, value) {
		state.items.splice(0);
		_.each(value, item => state.items.push(item));
	},
	setUser(state, value) {
		state.user.splice(0);
		_.each(value, item => state.user.push(item));
	},
};

export const getters = {
	getUserSubscriptions(state) {
		//абонемнеты
		let time = new Date().getTime() / 1000;
		let items = _.filter(state.user, item => item.date_end >= time);
		_.each(items, item => {
			item.fullName = item.name;
			if (item.count > 0) item.fullName += ' (' + item.client_count + '/' + item.count + ')';
			if (item.prepay > 0) item.fullName += ' (' + item.client_prepay + '/' + item.prepay + ')';
		});
		return items;
	},
	getOldUserSubscriptions(state) {
		//абонемнеты
		let time = new Date().getTime() / 1000;
		return _.filter(state.user, item => item.date_end < time);
	},
};
