import _ from 'lodash';
import helper from '@/plugins/helper.js';

export const state = () => ({
	id: '',
	name: '',
	surname: '',
	phone: '',
	email: '',
	view: '',
	rank: '',
	club: '',
	coupons: [],
	google_calendar: '',
	sex: '0',
	birthdate: null,
});

export const mutations = {
	setUser(state, value) {
		_.each(value, (item, key) => (state[key] = item));
	},
	setCoupons(state, value) {
		state.coupons = value;
	},
	logout(state) {
		_.each(state, (item, key) => (state[key] = ''));
	},
};

export const getters = {
	getUserCoupons(state) {
		//промокоды
		let items = state.coupons.filter(item => item.state === '1');
		_.each(items, item => {
			item.fullName = item.name;
		});
		return items;
	},
};
