<template>
	<nav class="row-item row-item_grow">
		<ul class="menu">
			<li class="menu-item">
				<nuxt-link class="menu-itemLink" href="/app/current" to="/current">Текущие услуги</nuxt-link>
			</li>
			<li class="menu-item">
				<nuxt-link class="menu-itemLink" href="/app/services" to="/services">Личные предложения</nuxt-link>
			</li>
			<li class="menu-item">
				<nuxt-link class="menu-itemLink" href="/app/profile" to="/profile">Настройки</nuxt-link>
			</li>
			<li class="menu-item">
				<nuxt-link class="menu-itemLink" href="/app/history" to="/history">История услуг</nuxt-link>
			</li>
		</ul>
	</nav>
</template>

<script>
export default {};
</script>
