import helper from '@/plugins/helper.js';

export default function({ route, store }) {
	if (route.path === '/services') {
		store.commit('api/addQuery', {
			task: 'getSessions',
			data: {
				date: helper.dateFormat(new Date()),
				count: 9,
				type: '0',
			},
		});
		return store.dispatch('init', { route });
	}
}
