export const state = () => ({
	lastPath: '/profile',
	promo: '', // предактивация промокода (берётся из get папаметра promo и localStorage)
});

export const mutations = {
	notice(state, value) {
		console.error(value);
	},
	redirect(state, value) {
		document.location = value;
	},
	lastPath: (state, value) => (state.lastPath = value),
	local: () => {},
	setPromo(state, value) {
		localStorage.setItem('promo', JSON.stringify(value));
		state.promo = value;
	},
};

export const actions = {
	async nuxtServerInit($store, context) {
		/*console.log(
			'nuxtServerInit',
			context.app.router.history.current.name,
			context.app.router.history.current.params
		);*/
		//return await $store.dispatch('init', context.app);
	},
	async fetch() {
		//console.log('store fetch');
	},
	async init($store, { route: $route }) {
		//console.log('init fetch', $store.state.api);
		//if (!$store.state.api.init)
		$store.commit('api/addQuery', {
			task: 'init',
			data: {
				view: $route.name,
				params: $route.params,
				server: process.server,
			},
		});
		$store.commit('api/setInit', true);
		return await $store
			.dispatch('api/send', {
				task: 'series',
				data: $store.state.api.query,
			})
			.then(() => {
				$store.commit('api/clearQuery');
				$store.commit('api/setInit', false);
			});
	},
	// предавторизация купона
	checkPromo($store, route) {
		if ('promo' in route.query) {
			$store.commit('setPromo', route.query.promo);
		} else if (localStorage.getItem('promo')) {
			$store.commit('setPromo', JSON.parse(localStorage.getItem('promo')));
		}
		console.log('checkPromo', $store.state.user.id, $store.state.promo);
		if ($store.state.user.id && $store.state.promo) {
			$store
				.dispatch('api/send', {
					task: 'activateCoupon',
					data: { coupon: $store.state.promo },
				})
				.then(() => {
					$store.commit('setPromo', '');
				});
		}
	},
};
