import _ from 'lodash';
import helper from '@/plugins/helper.js';

export const state = () => ({
	sessions: [],
	workers: [],
	exists: false,
});

export const getters = {
	getWeeks() {},
	getSessions(state) {
		let sessions = [];
		_.each(state.sessions, item => {
			let date = new Date();
			date.setTime(item.timestamp * 1000);

			sessions.push(
				Object.assign(
					{
						date: helper.dateFormat(date),
						time: date.getHours() * 60 + date.getMinutes(),
						time_format: helper.timeFormat(date.getHours() * 60 + date.getMinutes()),
					},
					item
				)
			);
		});
		return sessions;
	},
};

export const mutations = {
	setSessions(state, value) {
		state.sessions = value;
	},
	addSessions(state, value) {
		_.each(value, item => {
			if (
				_.findIndex(
					state.sessions,
					session => session.timestamp === item.timestamp && session.workers_id === item.workers_id
				) === -1
			)
				state.sessions.push(item);
		});
	},
	setExists(state, value) {
		state.exists = value;
	},
};

export const actions = {};
