import swal from 'sweetalert2';

class Helper {
	constructor() {}
	getWeekDay(date) {
		let days = [
			{
				name: 'Воскресенье',
				shortName: 'вс',
				holiday: true,
			},
			{
				name: 'Понедельник',
				shortName: 'пн',
				holiday: false,
			},
			{
				name: 'Вторник',
				shortName: 'вт',
				holiday: false,
			},
			{
				name: 'Среда',
				shortName: 'ср',
				holiday: false,
			},
			{
				name: 'Четверг',
				shortName: 'чт',
				holiday: false,
			},
			{
				name: 'Пятница',
				shortName: 'пт',
				holiday: false,
			},
			{
				name: 'Суббота',
				shortName: 'сб',
				holiday: true,
			},
		];
		return days[date.getDay()];
	}
	getYearMonth(date) {
		let months = [
			{
				name: 'Январь',
				declension: 'Января',
			},
			{
				name: 'Феврарь',
				declension: 'Февраля',
			},
			{
				name: 'Март',
				declension: 'Марта',
			},
			{
				name: 'Апрель',
				declension: 'Апреля',
			},
			{
				name: 'Май',
				declension: 'Мая',
			},
			{
				name: 'Июнь',
				declension: 'Июня',
			},
			{
				name: 'Июль',
				declension: 'Июля',
			},
			{
				name: 'Август',
				declension: 'Августа',
			},
			{
				name: 'Сентябрь',
				declension: 'Сентября',
			},
			{
				name: 'Октябрь',
				declension: 'Октября',
			},
			{
				name: 'Ноябрь',
				declension: 'Ноября',
			},
			{
				name: 'Декабрь',
				declension: 'Декабря',
			},
		];
		return months[date.getMonth()];
	}
	dateFormat(date) {
		let mm = date.getMonth() + 1; // getMonth() is zero-based
		let dd = date.getDate();

		return [date.getFullYear(), (mm > 9 ? '' : '0') + mm, (dd > 9 ? '' : '0') + dd].join('-');
	}
	timeFormat(time) {
		let hh = Math.floor(parseInt(time) / 60),
			mm = parseInt(time) % 60;

		hh = (hh > 9 ? '' : '0') + hh;
		mm = (mm > 9 ? '' : '0') + mm;

		return hh + ':' + mm;
	}
	timeRange(time, duration) {
		return [this.timeFormat(time), this.timeFormat(parseInt(time) + parseInt(duration))].join(' - ');
	}
	avatar(src) {
		return src ? src : 'components/com_sp/assets/images/noavatar.png';
	}
	confirm(additional) {
		let props = {
			type: 'warning',
			title: 'Вы уверены?',
			showCancelButton: true,
			confirmButtonText: 'Да',
			confirmButtonColor: '#3085d6',
			cancelButtonText: 'Отмена',
			cancelButtonColor: '#d33',
		};
		_.each(additional, (item, key) => (props[key] = item));
		return swal.fire(props);
	}
	notice(additional) {
		let props = {
			type: 'warning',
			title: 'Ошибка',
		};
		_.each(additional, (item, key) => (props[key] = item));
		return swal.fire(props);
	}
	priceFormat(value) {
		return this.number_format(Math.round(value, 0), 0, ' ', ' ');
	}
	number_format(number, decimals, dec_point, thousands_sep) {
		// Format a number with grouped thousands
		//
		// +   original by: Jonas Raoni Soares Silva (http://www.jsfromhell.com)
		// +   improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)
		// +	 bugfix by: Michael White (http://crestidg.com)
		var i, j, kw, kd, km;

		// input sanitation & defaults
		if (isNaN((decimals = Math.abs(decimals)))) {
			decimals = 2;
		}
		if (dec_point == undefined) {
			dec_point = ',';
		}
		if (thousands_sep == undefined) {
			thousands_sep = '.';
		}

		i = parseInt((number = (+number || 0).toFixed(decimals))) + '';

		if ((j = i.length) > 3) {
			j = j % 3;
		} else {
			j = 0;
		}

		km = j ? i.substr(0, j) + thousands_sep : '';
		kw = i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands_sep);
		//kd = (decimals ? dec_point + Math.abs(number - i).toFixed(decimals).slice(2) : "");
		kd = decimals
			? dec_point +
			  Math.abs(number - i)
					.toFixed(decimals)
					.replace(/-/, 0)
					.slice(2)
			: '';
		return km + kw + kd;
	}
	inclean(count, words) {
		if (count === '0') {
			return words[2];
		} else if (count === '1') {
			return words[0]; //штука
		} else if (count > 1 && count < 5) {
			return words[1]; //штуки
		} else if (count >= 5 && count < 21) {
			return words[2]; //штук
		} else {
			return this.inclean(count.substr(-1), words);
		}
	}
	array_chunks(arr, count) {
		arr.range = function(n) {
			// Array.range(5) --> [0,1,2,3,4]
			return Array.apply(null,Array(n)).map((x,i) => i)
		};
		arr.chunk = function(n) {
			return this.range(Math.ceil(this.length/n)).map((x,i) => this.slice(i*n,i*n+n));
		}
		return arr.chunk(count);
	}
}
export default new Helper();
