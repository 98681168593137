import _ from 'lodash';
import helper from '@/plugins/helper.js';

export const state = () => ({
	items: [],
	clientsEquipments: [],
});

export const mutations = {
	set(state, value) {
		state.items.splice(0);
		_.each(value, item => state.items.push(item));
	},
	delete(state, id) {
		let key = _.findIndex(state.items, item => item.id === id);
		if (key !== -1) state.items.splice(key, 1);
	},
	setClientsEquipments(state, value) {
		state.clientsEquipments.splice(0);
		_.each(value, item => state.clientsEquipments.push(item));
	},
	clearClientsEquipments(state) {
		state.clientsEquipments.splice(0);
	},
};

export const getters = {
	getItems(state) {
		let items = [];
		let time = new Date().getTime() / 1000;

		_.each(state.items, item => {
			item.expired = item.timestamp * 1 + item.duration * 60 < time;
			return items.push(item);
		});

		return items;
	},
};
