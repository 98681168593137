<template>
	<section id="app">
		<header>
			<div class="lay row row_sb row_mobileCenter">
				<div class="row-item row-item_side row-item_mobileCenter">
					<a class="logo" href="https://mysportexpert.ru" title="На главную">
						<img class="logo-img" src="~assets/images/logo@2x.png" alt="MySportExpert" />
					</a>
				</div>
				<top-menu></top-menu>
				<div class="row-item row-item_nowrap">
					<div class="logo logo_mt">
						<img class="logo-logout" src="~assets/images/logout@2x.png" alt="профиль" />
						<div class="logo-expand">
							<ul class="logo-menu">
								<template v-if="!$store.state.user.id">
									<li class="logo-menuItem">
										<nuxt-link to="/profile/login" class="logo-menuLink">Вход</nuxt-link>
									</li>
									<li class="logo-menuItem">
										<nuxt-link to="/profile/register" class="logo-menuLink">Регистрация</nuxt-link>
									</li>
								</template>
								<li v-else class="logo-menuItem">
									<a href="#logout" @click.prevent="logout" class="logo-menuLink">Выход</a>
								</li>
							</ul>
						</div>
					</div>
					<div class="logo logo_install" title="Установить" @click="promptInstall" v-if="canPrompt">
						<img class="logo-install" src="~assets/images/feature.svg" alt="установить" />
					</div>
				</div>
			</div>
		</header>
		<main>
			<nuxt />
		</main>
		<footer class="footer">
			<div class="lay">
				<div class="footer-buttons">
					<ul class="socialButtons">
						<li class="socialButtons-item socialButtons-item_vk">
							<a href="https://vk.com/mysportexpert" target="_blank" rel="nofollow noreferer">
								Vkontakte
							</a>
						</li>
						<li class="socialButtons-item socialButtons-item_in" v-if="false">
							<a href="https://www.instagram.com/mysportexpert/" target="_blank" rel="nofollow noreferer">
								Instagram
							</a>
						</li>
						<li class="socialButtons-item socialButtons-item_fb" v-if="false">
							<a href="https://www.facebook.com/MySportExpert/" target="_blank" rel="nofollow noreferer">
								Facebook
							</a>
						</li>
						<li class="socialButtons-item socialButtons-item_wa">
							<a
								href="https://api.whatsapp.com/send?phone=79104286969"
								target="_blank"
								rel="nofollow noreferer"
							>
								WhatsApp
							</a>
						</li>
					</ul>
					<div class="footer-label">
						<span>Есть вопросы?</span>
						Пишите в любое удобное время
					</div>
				</div>
				<div class="row row_sb">
					<div class="row-item left">
						<a href="/" class="logo">
							<img src="/templates/run/images/logo@2x.png" alt="Анализбиомеханики бега" />
						</a>
						<span>
							Центр спортивной физиологии
							<br />
							и биомеханики
						</span>
					</div>
					<div class="row-item">
						<div class="other_links">
							<blockquote>
								<a href="http://ta-lc.ru/" target="_blank" rel="noopener">
									<img src="/templates/run/images/talc-logo.png" alt="" />
								</a>
							</blockquote>
						</div>
					</div>
					<div class="row-item right">
						<a href="tel:+74953749422" class="phone">
							<i class="icon icon-phone"></i>
							+7 495 374 94 22
						</a>
						<div class="clear"></div>
						<a href="#" class="js_popup" data-popup="callback">Позвоните мне</a>
					</div>
				</div>
				<div class="links">
					<a href="/privacy_policy.pdf" target="_blank">Политика конфиденциальности</a>
					<a href="/user_agreement.pdf" target="_blank">Пользовательское соглашение</a>
					<a href="/personal_data.pdf" target="_blank">Согласие на обработку персональных данных</a>
					<a href="/price.pdf" target="_blank">Прейскурант центра</a>
				</div>
			</div>
		</footer>
		<div class="popup" v-show="loading">
			<div class="popup-layout">
				<img src="/app/loader.svg" alt="" />
			</div>
		</div>
	</section>
</template>

<script>
import topMenu from '@/components/menu.vue';

export default {
	head() {
		return {
			title: this.$store.state.api.meta.title,
			meta: [
				{ charset: 'utf-8' },
				{ name: 'viewport', content: 'width=device-width, initial-scale=1' },
				{ hid: 'description', name: 'description', content: this.$store.state.api.meta.description },
				{ hid: 'image', name: 'og:image', content: this.$store.state.api.meta.image },
			],
			link: [{ href: '/favicon.ico', rel: 'shortcut icon', type: 'image/vnd.microsoft.icon' }],
		};
	},
	data: () => ({
		canPrompt: false,
		dateFocus: new Date(),
	}),
	created() {
		return this.init();
	},
	mounted() {
		let timer = setInterval(() => {
			this.canPrompt = typeof window.deferredPrompt !== 'undefined';
			if (this.canPrompt) clearInterval(timer);
		}, 500);
		window.addEventListener('focus', () => {
			if (new Date().getTime() - this.dateFocus.getTime() > 24 * 60 * 60 * 1000) {
				//this.init();
				document.location.reload();
				this.dateFocus = new Date();
			}
		});
	},
	computed: {
		loading() {
			return this.$store.state.api.loading;
		},
	},
	methods: {
		//инициализация сессии посетителя
		async init() {
			if (process.client) {
				await this.$store.dispatch('init', { route: this.$route }).then(() => {
					this.checkLogin();
				});
				return await this.$store.dispatch('checkPromo', this.$route);
			}
		},
		logout() {
			this.$store.dispatch('api/send', { task: 'logout', data: {} }).then(() => {
				window.location.reload();
			});
		},
		checkLogin(_from) {
			if (_from && ['profile-login', 'profile-register', 'profile-reset'].indexOf(_from.name) === -1)
				this.$store.commit('lastPath', _from.fullPath);

			if (
				[
					'profile-login',
					'profile-login-telegram',
					'profile-register',
					'profile-reset',
					'profile-pay',
					'services',
					'services-services_id',
				].indexOf(this.$route.name) === -1
			) {
				if (!this.$store.state.user.id) {
					this.$router.push({ path: '/profile/login' });
				}
			}
		},
		promptInstall() {
			if (typeof window.deferredPrompt !== 'undefined') {
				window.deferredPrompt.prompt(); //.catch(function(e){console.log(e);});
			}
		},
	},
	components: {
		topMenu,
	},
	watch: {
		$route(to, _from) {
			this.checkLogin(_from);
		},
	},
	middleware: ['services'],
};
</script>
