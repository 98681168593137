class oAuth2Client {
	constructor() {
		this.code = '';
	}
	login(authUrl) {
		return new Promise((resolve) => {
			let win = window.open(authUrl, 'googleOAuth2', 'width=800, height=600');

			let pollTimer = window.setInterval(() => {
				try {
					if (win.document.URL.indexOf('?code=') !== -1) {
						let url = win.document.URL;
						window.clearInterval(pollTimer);
						this.code = this.gup(url, 'code');
						win.close();
						resolve(this);
					}
				} catch (e) {
					//reject(e);
				}
			}, 100);
		});
	}

	validateToken(token) {
		$.ajax({
			url: this.VALIDURL + token,
			data: null,
			success: function(responseText) {
				this.getUserInfo();
			},
			dataType: 'jsonp',
		});
	}

	getUserInfo() {
		$.ajax({
			url: 'https://www.googleapis.com/oauth2/v1/userinfo?access_token=' + acToken,
			data: null,
			success: function(resp) {
				user = resp;
				console.log(user);
				$('#uName').append(user.name);
				$('#imgHolder').attr('src', user.picture);
			},
			dataType: 'jsonp',
		});
	}

	/**
	 * @credits http://www.netlobo.com/url_query_string_javascript.html
	 */
	gup(url, name) {
		name = name.replace(/[[]/, '[').replace(/[]]/, ']');
		let regexS = '[?&]' + name + '=([^&#]*)';
		let regex = new RegExp(regexS);
		let results = regex.exec(url);
		if (results == null) return '';
		else return results[1];
	}
}
export default new oAuth2Client();
