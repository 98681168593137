import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _16955928 = () => interopDefault(import('../pages/current/index.vue' /* webpackChunkName: "pages/current/index" */))
const _6b2394c7 = () => interopDefault(import('../pages/history/index.vue' /* webpackChunkName: "pages/history/index" */))
const _942ab8c8 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _28816ae1 = () => interopDefault(import('../pages/services/index.vue' /* webpackChunkName: "pages/services/index" */))
const _0f0dbaf6 = () => interopDefault(import('../pages/profile/login/index.vue' /* webpackChunkName: "pages/profile/login/index" */))
const _0d8d0416 = () => interopDefault(import('../pages/profile/pay/index.vue' /* webpackChunkName: "pages/profile/pay/index" */))
const _3485c5e8 = () => interopDefault(import('../pages/profile/register/index.vue' /* webpackChunkName: "pages/profile/register/index" */))
const _377b7d48 = () => interopDefault(import('../pages/profile/reset/index.vue' /* webpackChunkName: "pages/profile/reset/index" */))
const _e6ed81e6 = () => interopDefault(import('../pages/profile/login/telegram.vue' /* webpackChunkName: "pages/profile/login/telegram" */))
const _a96cbabe = () => interopDefault(import('../pages/services/_services_id/index.vue' /* webpackChunkName: "pages/services/_services_id/index" */))
const _1205a6fc = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/current",
    component: _16955928,
    name: "current"
  }, {
    path: "/history",
    component: _6b2394c7,
    name: "history"
  }, {
    path: "/profile",
    component: _942ab8c8,
    name: "profile"
  }, {
    path: "/services",
    component: _28816ae1,
    name: "services"
  }, {
    path: "/profile/login",
    component: _0f0dbaf6,
    name: "profile-login"
  }, {
    path: "/profile/pay",
    component: _0d8d0416,
    name: "profile-pay"
  }, {
    path: "/profile/register",
    component: _3485c5e8,
    name: "profile-register"
  }, {
    path: "/profile/reset",
    component: _377b7d48,
    name: "profile-reset"
  }, {
    path: "/profile/login/telegram",
    component: _e6ed81e6,
    name: "profile-login-telegram"
  }, {
    path: "/services/:services_id",
    component: _a96cbabe,
    name: "services-services_id"
  }, {
    path: "/",
    component: _1205a6fc,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config.app && config.app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
