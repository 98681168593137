<template>
	<div>
		<h1>
			<span>Google Календарь</span>
			<svg
				class="icon icon-spreadsheets"
				enable-background="new 0 0 512 512"
				viewBox="0 0 512 512"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g>
					<g>
						<path
							d="m466.414 152h-420c-8.291 0-15-6.709-15-15v-92c0-24.814 20.186-45 45-45h360c24.814 0 45 20.186 45 45v92c0 8.291-6.709 15-15 15z"
							fill="#e3e7ea"
						/>
					</g>
					<path
						d="m436.414 0h-180v152h210c8.291 0 15-6.709 15-15v-92c0-24.814-20.186-45-45-45z"
						fill="#babac0"
					/>
					<g>
						<g>
							<g>
								<path
									d="m30.835 316.998c.005-.022.007-.033.007-.033l-29.842-142.165c-2.1-13.5 1.5-27.001 9.901-36.901 8.699-10.199 21.298-15.899 34.498-15.899h421.201c13.2 0 25.8 5.7 34.499 15.899 8.401 9.901 12.001 23.401 9.901 36.301l-29.842 142.836 29.842 142.164c2.1 13.5-1.5 27.001-9.901 36.899-8.699 10.201-21.298 15.901-34.498 15.901h-421.202c-13.2 0-25.8-5.7-34.499-15.901-8.4-9.899-12-23.399-9.9-36.299 0 0 29.317-140.325 29.835-142.802z"
									fill="#4086f4"
								/>
							</g>
						</g>
						<path
							d="m30.842 316.965s-.002.011-.007.033h450.331l29.834-142.798c2.1-12.9-1.5-26.4-9.901-36.301-8.699-10.199-21.298-15.899-34.498-15.899h-421.202c-13.2 0-25.8 5.7-34.499 15.899-8.4 9.901-12 23.401-9.9 36.901z"
							fill="#4175df"
						/>
						<g><circle cx="106" cy="75" fill="#fff5f5" r="15" /></g>
						<g><circle cx="406" cy="75" fill="#e3e7ea" r="15" /></g>
						<g>
							<path
								d="m196 422c-25.371 0-48.12-16.069-56.572-40.005-2.769-7.808 1.318-16.377 9.141-19.131 7.778-2.783 16.377 1.333 19.131 9.141 4.233 11.953 15.614 19.995 28.3 19.995 16.538 0 30-13.462 30-30s-13.462-30-30-30h-18.647c-8.276 0-15-6.709-15-15s6.724-15 15-15h18.647c16.538 0 30-13.462 30-30s-13.462-30-30-30c-12.686 0-24.067 8.042-28.301 19.995-2.754 7.822-11.367 11.924-19.131 9.141-7.822-2.754-11.909-11.323-9.141-19.131 8.453-23.936 31.202-40.005 56.573-40.005 33.091 0 60 26.909 60 60 0 17.9-7.881 33.999-20.361 45 12.48 11.001 20.361 27.1 20.361 45 0 33.091-26.909 60-60 60z"
								fill="#fff5f5"
							/>
						</g>
						<path
							d="m148.568 271.136c7.764 2.783 16.377-1.318 19.131-9.141 4.234-11.953 15.616-19.995 28.301-19.995 16.538 0 30 13.462 30 30s-13.462 30-30 30h-18.647c-8.276 0-14.999 6.708-15 14.998h73.288c12.479-11.001 20.359-27.099 20.359-44.998 0-33.091-26.909-60-60-60-25.371 0-48.12 16.069-56.572 40.005-2.769 7.808 1.318 16.377 9.14 19.131z"
							fill="#e3e7ea"
						/>
						<g>
							<path
								d="m346 422c-8.291 0-15-6.709-15-15v-151.978l-21.68 14.458c-6.914 4.57-16.216 2.725-20.801-4.16-4.6-6.899-2.739-16.201 4.16-20.801l45-30c4.6-3.062 10.532-3.354 15.396-.747 4.878 2.608 7.925 7.691 7.925 13.228v180c0 8.291-6.709 15-15 15z"
								fill="#fff5f5"
							/>
						</g>
						<path
							d="m309.32 269.48 21.68-14.458v61.975h30v-89.997c0-5.537-3.047-10.62-7.925-13.228-4.863-2.607-10.796-2.314-15.396.747l-45 30c-6.899 4.6-8.76 13.901-4.16 20.801 4.586 6.885 13.887 8.731 20.801 4.16z"
							fill="#e3e7ea"
						/>
					</g>
				</g>
			</svg>
		</h1>
		<p>Получать расписание занятий в Google календарь:</p>
		<div class="button2" @click="auth" v-if="calendar.authUrl"><span>Авторизация</span></div>
		<div class="button2" @click="select" v-if="calendar.access_token">
			<span>
				<template v-if="google_calendar">
					{{ google_calendar }}
				</template>
				<template v-else>
					Выбрать календарь
				</template>
			</span>
		</div>
		<popup v-if="selecting" @close="selecting = false">
			<div class="frame">
				<h2>Выберите календарь</h2>
				<div class="form">
					<div class="form-control">
						<v-select
							placeholder="Выберите календарь из списка"
							label="label"
							:options="calendars"
							v-model="selecting_calendar"
						></v-select>
					</div>
					<div class="form-control text-center">
						<div class="form-buttons form-buttons">
							<div class="form-buttonsItem">
								<div class="button" @click="save"><span>Сохранить</span></div>
							</div>
							<div class="form-buttonsItem" v-if="google_calendar">
								<div class="button2" @click="cancel"><span>Отключить календарь</span></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</popup>
	</div>
</template>
<script>
import oAuth2Client from '../plugins/oAuth2Client.js';

export default {
	data() {
		return {
			selecting: false,
			selecting_calendar: null, //{ value: this.$store.state.user.google_calendar, label: '' } || '',
		};
	},
	created() {
		this.$store.dispatch('api/send', {
			task: 'googleCalendarAuth',
		});
		this.selecting_calendar = this.calendars.find(i => i.id === this.$store.state.user.google_calendar);
	},
	computed: {
		calendar() {
			return this.$store.state.api.calendar;
		},
		calendars() {
			let items = this.$store.state.api.calendarList.items || [];
			return items
				.filter(i => i.accessRole === 'owner')
				.map(i => ({
					value: i.id,
					label: i.summary,
				}));
		},
		google_calendar() {
			return this.$store.state.user.google_calendar;
		},
	},
	methods: {
		auth() {
			oAuth2Client.login(this.calendar.authUrl).then(client => {
				this.$store
					.dispatch('api/send', {
						task: 'googleCalendarLogin',
						data: client,
					})
					.then(() => {
						if (!!this.calendar.access_token) {
							this.select();
						}
					});
			});
		},
		select() {
			this.$store
				.dispatch('api/send', {
					task: 'googleCalendarList',
				})
				.then(() => {
					this.selecting = true;
				});
		},
		save() {
			this.$store
				.dispatch('api/send', {
					task: 'googleCalendarSave',
					data: {
						google_calendar: this.selecting_calendar ? this.selecting_calendar.value : '',
					},
				})
				.then(() => {
					this.selecting = false;
				});
		},
		cancel() {
			this.$store
				.dispatch('api/send', {
					task: 'googleCalendarCancel',
				})
				.then(() => {
					this.selecting = false;
				});
		},
	},
};
</script>
