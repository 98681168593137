import _ from 'lodash';
import helper from '@/plugins/helper.js';

export const state = () => ({
	items: [],
});

export const mutations = {
	set(state, value) {
		state.items.splice(0);
		_.each(value, item => state.items.push(item));
	},
};
