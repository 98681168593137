import Vue from 'vue';
import popup from '@/components/popup.vue';
import AwesomeMask from 'awesome-mask';
import vSelect from 'vue-select';

//регистрация директив
Vue.directive('mask', AwesomeMask);

//регистрация глобальных компонентов
Vue.component('popup', popup);

import 'vue-select/dist/vue-select.css';
Vue.component('v-select', vSelect);
